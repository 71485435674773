import React, { useState, useEffect } from 'react';
import { 
  Table, Button, Input, Space, Popconfirm, message, Card, 
  Typography, Row, Col, Modal, Form, Spin 
} from 'antd';
import { 
  PlusOutlined, SearchOutlined, EditOutlined, 
  DeleteOutlined, LockOutlined, ExclamationCircleOutlined 
} from '@ant-design/icons';
import {IconUserCheck, IconUserCancel} from '@tabler/icons-react'
import { useNavigate } from 'react-router-dom';
import { userAPI } from '../../api/userAPI';

const { Title } = Typography;
const { confirm } = Modal;

const UserList = () => {
  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0
  });
  const [resetPasswordVisible, setResetPasswordVisible] = useState(false);
  const [resetPasswordForm] = Form.useForm();
  const [selectedUser, setSelectedUser] = useState(null);
  const [resetPasswordLoading, setResetPasswordLoading] = useState(false);
  
  const navigate = useNavigate();

  // Fungsi untuk memuat data user
  const fetchUsers = async (page = 1, pageSize = 10, search = '') => {
    try {
      setLoading(true);
      const response = await userAPI.getUsers(page, pageSize, search);
      
      setUsers(response.data);
      setPagination({
        current: page,
        pageSize: pageSize,
        total: response.meta.total
      });
    } catch (error) {
      message.error('Failed load user data');
      console.error('Error fetching users:', error);
    } finally {
      setLoading(false);
    }
  };

  // Load data saat komponen dimuat
  useEffect(() => {
    fetchUsers(pagination.current, pagination.pageSize, searchText);
  }, []);

  // Handle perubahan pagination dan filter
  const handleTableChange = (pagination) => {
    fetchUsers(pagination.current, pagination.pageSize, searchText);
  };

  // Handle search
  const handleSearch = () => {
    fetchUsers(1, pagination.pageSize, searchText);
  };

  // Fungsi untuk menghapus user
  const handleDelete = (userId) => {
    confirm({
      title: 'Are you sure want to delete this user ?',
      icon: <ExclamationCircleOutlined />,
      content: 'This action can\'t be undone.',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'Cancel',
      onOk: async () => {
        try {
          await userAPI.deleteUser(userId);
          message.success('User has been deleted');
          fetchUsers(pagination.current, pagination.pageSize, searchText);
        } catch (error) {
          message.error('Failed to delete user');
          console.error('Error deleting user:', error);
        }
      }
    });
  };

  // Fungsi untuk membuka modal reset password
  const showResetPasswordModal = (user) => {
    setSelectedUser(user);
    setResetPasswordVisible(true);
    resetPasswordForm.resetFields();
  };

  // Fungsi untuk reset password
  const handleResetPassword = async (values) => {
    if (!selectedUser) return;
    
    try {
      setResetPasswordLoading(true);
      await userAPI.resetPassword(selectedUser.id, values.password);
      message.success('Password has been reset');
      setResetPasswordVisible(false);
    } catch (error) {
      message.error('Failed to reset password');
      console.error('Error resetting password:', error);
    } finally {
      setResetPasswordLoading(false);
    }
  };

  // Konfigurasi kolom tabel
  const columns = [
    {
      title: 'Nama',
      dataIndex: 'name',
      key: 'name',
      // sorter: true
    },
    {
      title: 'Phone',
      dataIndex: 'phone',
      key: 'phone'
    },
    {
      title: 'Role',
      dataIndex: 'role_name',
      key: 'role_name',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      align: 'center',
      render: (status) => (
        <span style={{ 
          color: status == 1 ? 'green' : 'red',
          fontWeight: 'bold'
        }}>
          {status == 1 ? (
            (<IconUserCheck/>)
          ) : (<IconUserCancel/>)}
        </span>
      )
    },
    {
      title: 'Register Date',
      dataIndex: 'register_date',
      key: 'register_date',
      align: 'center',
      render: (text) => new Date(text).toLocaleDateString('id-ID')
    },
    {
      title: 'Action',
      key: 'action',
      align:'center',
      width: 200,
      render: (_, record) => (
        <Space size="small">
          <Button 
            type="primary" 
            icon={<EditOutlined />} 
            onClick={() => navigate(`/users/edit/${record.id}`)}
          >
            Edit
          </Button>
          <Button 
            icon={<LockOutlined />}
            onClick={() => showResetPasswordModal(record)}
          >
            Reset
          </Button>
          <Popconfirm
            title="Sure want to delete this user?"
            onConfirm={() => handleDelete(record.id)}
            okText="Yes"
            cancelText="Cancel"
          >
            <Button 
              danger 
              icon={<DeleteOutlined />}
            >
              Hapus
            </Button>
          </Popconfirm>
        </Space>
      )
    }
  ];

  return (
    <div>
      <Card>
        <Row justify="space-between" align="middle" style={{ marginBottom: 16 }}>
          <Col>
            <Title level={2}>User Management</Title>
          </Col>
          <Col>
            <Button
              type="primary"
              icon={<PlusOutlined />}
              onClick={() => navigate('/users/create')}
            >
              Create New
            </Button>
          </Col>
        </Row>

        <Row style={{ marginBottom: 16 }}>
          <Col span={8}>
            <Input
              placeholder="Search User..."
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
              onPressEnter={handleSearch}
              suffix={<SearchOutlined onClick={handleSearch} style={{ cursor: 'pointer' }} />}
            />
          </Col>
        </Row>

        <Table
          className="table-striped-rows"
          columns={columns}
          dataSource={users}
          rowKey="id"
          pagination={pagination}
          loading={loading}
          onChange={handleTableChange}
        />
      </Card>

      {/* Modal Reset Password */}
      <Modal
        title="Reset Password"
        open={resetPasswordVisible}
        onCancel={() => setResetPasswordVisible(false)}
        footer={null}
      >
        <Form
          form={resetPasswordForm}
          layout="vertical"
          onFinish={handleResetPassword}
        >
          <Form.Item
            name="password"
            label="Password Baru"
            rules={[
              { required: true, message: 'Password baru wajib diisi!' },
              { min: 6, message: 'Password minimal 6 karakter' }
            ]}
          >
            <Input.Password />
          </Form.Item>
          <Form.Item
            name="password_confirmation"
            label="Konfirmasi Password"
            dependencies={['password']}
            rules={[
              { required: true, message: 'Konfirmasi password wajib diisi!' },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue('password') === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error('Password tidak cocok!'));
                },
              }),
            ]}
          >
            <Input.Password />
          </Form.Item>
          <Form.Item>
            <Row justify="end">
              <Space>
                <Button onClick={() => setResetPasswordVisible(false)}>Batal</Button>
                <Button type="primary" htmlType="submit" loading={resetPasswordLoading}>
                  Reset Password
                </Button>
              </Space>
            </Row>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default UserList;