import React, { useState, useEffect } from 'react';
import { Typography, Row, Col, Card, Statistic, Spin, message, Table } from 'antd';
import { IconUserBolt, IconUsersGroup, IconUserPin, IconShoppingCart } from '@tabler/icons-react';
import { dashboardAPI } from '../api/dashboardAPI';
import { customerAPI } from '../api/customerAPI';
import { salesVisitAPI } from '../api/salesVisitAPI';
import { Line } from 'react-chartjs-2';
import GoogleMapComponent from '../components/GoogleMapComponent';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title as ChartTitle,
  Tooltip,
  Legend,
} from 'chart.js';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, ChartTitle, Tooltip, Legend);

const { Title } = Typography;

const Dashboard = () => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({
    orders: 0,
    visits: 0,
    customers: 0,
    users: 0,
  });
  const [dataChartDaily, setDataChartDaily] = useState({
    dailyLabels: [],
    dailyDatas: [],
  });
  const [markers, setMarkers] = useState([]);
  const [leaderBoard, setLeaderBoard] = useState([]);

  const fetchDashboardCounter = async () => {
    try {
      setLoading(true);
      const response = await dashboardAPI.getCounterDaily();
      setData({
        orders: response.data.orders,
        visits: response.data.visits,
        customers: response.data.customers,
        users: response.data.users,
      });
    } catch (error) {
      message.error('Gagal memuat data dashboard');
      console.error('Error fetching dashboard data:', error);
    } finally {
      setLoading(false);
    }
  };

  const fetchDashboardChartDailyVisits = async () => {
    try {
      const response = await dashboardAPI.getChartDaily();
      setDataChartDaily({
        dailyLabels: response.data.labels,
        dailyDatas: response.data.datasets,
      });
    } catch (error) {
      message.error('Gagal memuat data kunjungan harian');
      console.error('Error fetching daily visits:', error);
    }
  };

  const fetchCustomerLocation = async () => {
    try {
      const response = await customerAPI.getCustomerLocation();
      const markers = response.data.map((customer) => ({
        lat: parseFloat(customer?.geolocation ? customer.geolocation.split(',')[0].trim() : null),
        lng: parseFloat(customer?.geolocation ? customer.geolocation.split(',')[1].trim() : null),
        title: customer.name,
        address: customer.address,
        image: customer.image ? 'https://cdn.rms.web.id/' + customer.image : ''
      }));

      setMarkers(markers);

    } catch (error) {
      message.error('Gagal memuat data kunjungan harian');
      console.error('Error fetching daily visits:', error);
    }
  };

  const fetchLeaderBoard = async () => {
    try {
      const response = await salesVisitAPI.getLeaderBoard();
      

      setLeaderBoard(response.data);

    } catch (error) {
      message.error('Gagal memuat data kunjungan harian');
      console.error('Error fetching daily visits:', error);
    }
  };

  useEffect(() => {
    fetchDashboardCounter();
    fetchDashboardChartDailyVisits();
    fetchCustomerLocation();
    fetchLeaderBoard();
  }, []);

  if (loading) {
    return (
      <div style={{ textAlign: 'center', padding: '50px' }}>
        <Spin size="large" />
        <p>Memuat data dashboard...</p>
      </div>
    );
  }

  const statistics = [
    {
      title: 'Sales Visit',
      value: data.visits,
      icon: <IconUserPin />,
      color: '#1890ff',
    },
    {
      title: 'Orders',
      value: data.orders,
      icon: <IconShoppingCart />,
      color: '#52c41a',
    },
    {
      title: 'Customers',
      value: data.customers,
      icon: <IconUsersGroup />,
      color: '#ff7a45',
    },
    {
      title: 'Monthly Active Users',
      value: data.users,
      icon: <IconUserBolt />,
      color: '#aa00ff',
    },
    {
      title: 'Monthly Active Users',
      value: data.users,
      icon: <IconUserBolt />,
      color: '#3d3d3d',
    },
    {
      title: 'Monthly Active Users',
      value: data.users,
      icon: <IconUserBolt />,
      color: '#f5222d',
    },
  ];

  const chartData = {
    labels: dataChartDaily.dailyLabels,
    datasets: dataChartDaily.dailyDatas,
  };
  console.log(chartData)
  const chartOptions = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: 'Daily Sales Visits vs Target',
      },
    },
  };

  const columns = [
    { 
      title: 'Sales Name',
      dataIndex: 'sales_name',
      key: 'sales_name' 
    },
    { 
      title: 'Total Visit',
      dataIndex: 'total_visit',
      align: 'center',
      key: 'total_visit'
    },
    { 
      title: 'Total Duration',
      dataIndex: 'total_duration',
      key: 'total_duration'
    },
  ]

  const mapCenter = {
    lat: -6.200000,
    lng: 106.816666,
  };

  return (
    <div>
      <Title level={4}>Dashboard</Title>

      <Row gutter={[16, 16]}>
        {statistics.map((stat, index) => (
          <Col xs={12} sm={12} md={8} lg={4} key={index}>
            <Card>
              <Statistic
                title={stat.title}
                value={stat.value}
                valueStyle={{ color: stat.color }}
                prefix={stat.icon}
              />
            </Card>
          </Col>
        ))}
      </Row>

      <Row gutter={[16, 16]} style={{ marginTop: 20 }}>
        <Col span={16}>
          <Card>
            <Line data={chartData} options={chartOptions} />
          </Card>
        </Col>

        <Col span={8}>
          <Table
            columns={columns}
            dataSource={leaderBoard}
            rowKey="id"
            pagination={false}
          />
        </Col>
      </Row>

      <Row gutter={[16, 16]} style={{ marginTop: 20 }}>
        <Col span={24}>
          <Card title="Google Maps">
            <GoogleMapComponent markers={markers} center={mapCenter} zoom={10} />
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default Dashboard;