import React, { useState } from 'react';
import { GoogleMap, LoadScript, MarkerClusterer, Marker, InfoWindow } from '@react-google-maps/api';

const GoogleMapComponent = ({ markers, center, zoom }) => {
  const mapContainerStyle = {
    width: '100%',
    height: '400px',
  };

  // const isGoogleApiLoaded = () => {
  //   return !!window.google;
  // };

  // State to track the currently selected marker
  const [selectedMarker, setSelectedMarker] = useState(null);

  // if (!isGoogleApiLoaded()) {
  //   return <div>Loading Google Maps...</div>;
  // }

  return (
    <LoadScript googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}>
      <GoogleMap mapContainerStyle={mapContainerStyle} center={center} zoom={zoom}>
        <MarkerClusterer>
          {(clusterer) =>
            markers.map((marker, index) => (
              <Marker
                key={index}
                position={{ lat: marker.lat, lng: marker.lng }}
                clusterer={clusterer}
                onClick={() => setSelectedMarker(marker)} // Set the selected marker on click
              />
            ))
          }
        </MarkerClusterer>

        {/* InfoWindow for the selected marker */}
        {selectedMarker && (
          <InfoWindow
            position={{ lat: selectedMarker.lat, lng: selectedMarker.lng }}
            onCloseClick={() => setSelectedMarker(null)} // Close the InfoWindow
          >
            <div style={{ width: '200px' }}>
              <h4>{selectedMarker.title}</h4>
              <p>{selectedMarker.address}</p>
              {selectedMarker.image && (
                <img
                  src={selectedMarker.image}
                  alt={selectedMarker.name}
                  style={{ width: '100px', borderRadius: '8px' }}
                />
              )}
            </div>
          </InfoWindow>
        )}
      </GoogleMap>
    </LoadScript>
  );
};

export default GoogleMapComponent;