import axios from 'axios';

const API_URL = 'https://cmms.therubymedia.com/api';

export const login = async (username, password) => {
  try {
    const response = await axios.post(`${API_URL}/login`, {
      username,
      password
    });
    
    // Simpan token JWT di localStorage
    const { data } = response.data;

    
    localStorage.setItem('rms-token', data.token);
    localStorage.setItem('rms-user', JSON.stringify(data));
    
    return {
      success: true,
      user: data
    };
  } catch (error) {
    console.error('Login error:', error);
    return {
      success: false,
      message: error.response?.data?.message || 'Login gagal. Silakan coba lagi.'
    };
  }
};

export const logout = () => {
  localStorage.removeItem('rms-token');
  localStorage.removeItem('rms-user');
};

export const isAuthenticated = () => {
  return !!localStorage.getItem('rms-token');
};

export const getUser = () => {
  const user = localStorage.getItem('rms-user');
  return user ? JSON.parse(user) : null;
};

export const getToken = () => {
  return localStorage.getItem('rms-token');
};

// Setup axios interceptor untuk menambahkan token ke setiap request
export const setupAxiosInterceptors = () => {
  axios.interceptors.request.use(
    (config) => {
      const token = getToken();
      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );
  
  // Handle token expired atau unauthorized
  axios.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response && error.response.status === 401) {
        // Token expired atau tidak valid
        logout();
        window.location.href = '/login';
      }
      return Promise.reject(error);
    }
  );
};