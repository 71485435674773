import api from './index';

const apiVersion = 'v2.0';
export const userAPI = {
  /**
   * Mendapatkan daftar pengguna dengan paginasi dan pencarian
   * @param {number} page - Halaman saat ini
   * @param {number} perPage - Jumlah item per halaman
   * @param {string} search - Kata kunci pencarian
   * @returns {Promise} - Promise yang menghasilkan data dan metadata pengguna
   */
  getUsers: async (page = 1, perPage = 10, search = '') => {
    try {
      const response = await api.get(`/${apiVersion}/users`, {
        params: {
          currpage: page,
          perpage: perPage,
          search
        }
      });
      return response.data;
    } catch (error) {
      console.error('API Error - getUsers:', error);
      throw error;
    }
  },

  /**
   * Mendapatkan data pengguna berdasarkan ID
   * @param {number|string} id - ID pengguna
   * @returns {Promise} - Promise yang menghasilkan data pengguna
   */
  getUser: async (id) => {
    try {
      const response = await api.get(`/${apiVersion}/users/${id}`);
      return response.data.data;
    } catch (error) {
      console.error(`API Error - getUser ${id}:`, error);
      throw error;
    }
  },

  /**
   * Membuat pengguna baru
   * @param {Object} userData - Data pengguna baru
   * @returns {Promise} - Promise yang menghasilkan data pengguna yang dibuat
   */
  createUser: async (userData) => {
    try {
      const response = await api.post(`/${apiVersion}/users`, userData);
      return response.data.data;
    } catch (error) {
      console.error('API Error - createUser:', error);
      throw error;
    }
  },

  /**
   * Memperbarui data pengguna
   * @param {number|string} id - ID pengguna
   * @param {Object} userData - Data pengguna yang diperbarui
   * @returns {Promise} - Promise yang menghasilkan data pengguna yang diperbarui
   */
  updateUser: async (id, userData) => {
    try {
      const response = await api.put(`/${apiVersion}/users/${id}`, userData);
      return response.data.data;
    } catch (error) {
      console.error(`API Error - updateUser ${id}:`, error);
      throw error;
    }
  },

  /**
   * Menghapus pengguna
   * @param {number|string} id - ID pengguna
   * @returns {Promise} - Promise yang menghasilkan respons penghapusan
   */
  deleteUser: async (id) => {
    try {
      const response = await api.delete(`/${apiVersion}/users/${id}`);
      return response.data;
    } catch (error) {
      console.error(`API Error - deleteUser ${id}:`, error);
      throw error;
    }
  },

  /**
   * Mengatur ulang password pengguna
   * @param {number|string} id - ID pengguna
   * @param {string} password - Password baru
   * @returns {Promise} - Promise yang menghasilkan respons reset password
   */
  resetPassword: async (id, password) => {
    try {
      const response = await api.post(`/${apiVersion}/users/${id}/reset-password`, {
        password,
        password_confirmation: password
      });
      return response.data;
    } catch (error) {
      console.error(`API Error - resetPassword ${id}:`, error);
      throw error;
    }
  }
};