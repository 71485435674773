import api from './index';

const apiVersion = 'v2.0';
export const orderAPI = {
  /**
   * Mendapatkan daftar pengguna dengan paginasi dan pencarian
   * @param {number} page - Halaman saat ini
   * @param {number} perPage - Jumlah item per halaman
   * @param {string} search - Kata kunci pencarian
   * @returns {Promise} - Promise yang menghasilkan data dan metadata pengguna
   */
  getOrders: async (params) => {
    try {
      const response = await api.get(`/${apiVersion}/work-order`, {
        params: params
      });
      return response.data;
    } catch (error) {
      console.error('API Error - getOrders:', error);
      throw error;
    }
  },

  /**
   * Mendapatkan data pengguna berdasarkan ID
   * @param {number|string} id - ID pengguna
   * @returns {Promise} - Promise yang menghasilkan data pengguna
   */
  getOrder: async (id) => {
    try {
      const response = await api.get(`/${apiVersion}/work-order/${id}`);
      return response.data.data;
    } catch (error) {
      console.error(`API Error - getOrder ${id}:`, error);
      throw error;
    }
  },


  getOrderHistory: async (custId) => {
    try {
      const response = await api.get(`/${apiVersion}/work-order/${custId}/customer-history`);
      return response.data.data;
    } catch (error) {
      console.error(`API Error - getOrderHistory ${custId}:`, error);
      throw error;
    }
  },

  
  getOrderByCustomer: async (customerId, params) => {
    try {
      const response = await api.get(`/${apiVersion}/work-order/${customerId}/customer-history`, {
        params: params
      });
      return response.data;
    } catch (error) {
      console.error('API Error - getOrders:', error);
      throw error;
    }
  },
  
};