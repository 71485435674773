import React, { useState, useEffect } from 'react';
import { 
  Form, Input, Button, Card, Select,
  Row, Col, message, Spin, Typography, Space, Upload, Avatar
} from 'antd';
import { ArrowLeftOutlined, SaveOutlined, UploadOutlined } from '@ant-design/icons';
import { useNavigate, useParams } from 'react-router-dom';
import { customerAPI } from '../../api/customerAPI';
import TextArea from 'antd/es/input/TextArea';
import { GoogleMap, LoadScript, Marker} from '@react-google-maps/api';
const { Title } = Typography;
const { Option } = Select;

const isGoogleApiLoaded = () => {
  return !!window.google;
};

const placeholderImage = "https://via.placeholder.com/300x200?text=Placeholder+Image";

const CustomerForm = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const { id } = useParams();
  const isEditing = !!id;

  const [loading, setLoading] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [coverImage, setCoverImage] = useState(null);
  const [location, setLocation] = useState({ lat: -6.200000, lng: 106.816666 }); // Default location (Jakarta)

  // Memuat data user untuk mode edit
  useEffect(() => {
    if (isEditing) {
      const fetchUser = async () => {
        try {
          setLoading(true);
          const user = await customerAPI.getCustomer(id);
          form.setFieldsValue({
            name: user.name,
            address: user.address,
            role: user.role,
            active: user.active
          });
          setCoverImage(user.cover);
        } catch (error) {
          message.error('Gagal memuat data pengguna');
          console.error('Error fetching user:', error);
          navigate('/customers');
        } finally {
          setLoading(false);
        }
      };
      
      fetchUser();
    }
  }, [id, form, navigate, isEditing]);

  // Handle submit form
  const handleSubmit = async (values) => {
    try {
      setSubmitting(true);
      if (isEditing) {
        await customerAPI.updateCustomer(id, values);
        message.success('Pengguna berhasil diperbarui');
      } else {
        await customerAPI.createCustomer(values);
        message.success('Pengguna berhasil dibuat');
      }
      navigate('/customers');
    } catch (error) {
      const errorMsg = error.response?.data?.message || 'Terjadi kesalahan';
      message.error(errorMsg);
      console.error('Error saving user:', error);
    } finally {
      setSubmitting(false);
    }
  };

  // Handle image upload
  const handleImageUpload = ({ file }) => {
    const reader = new FileReader();
    reader.onload = () => {
      setCoverImage(reader.result); // Set the uploaded image as base64
      message.success("Image uploaded successfully!");
    };
    reader.readAsDataURL(file);
  };

  // Handle map click to set location
  const handleMapClick = (event) => {
    setLocation({ lat: event.latLng.lat(), lng: event.latLng.lng() });
  };

  if (loading) {
    return (
      <div style={{ textAlign: 'center', padding: '50px' }}>
        <Spin size="large" />
        <p>Memuat data pengguna...</p>
      </div>
    );
  }

  return (
    <Card>
      <Row justify="space-between" align="middle" style={{ marginBottom: 24 }}>
        <Col>
          <Title level={2}>{isEditing ? 'Edit Pengguna' : 'Tambah Pengguna'}</Title>
        </Col>
        <Col>
          <Button 
            icon={<ArrowLeftOutlined />} 
            onClick={() => navigate('/customers')}
          >
            Kembali
          </Button>
        </Col>
      </Row>


      <Form
        form={form}
        layout="vertical"
        onFinish={handleSubmit}
        initialValues={{ active: true, role: 'customer' }}
      >
        <Row gutter={16}>
          <Col span={12}>
            <Row gutter={16}>
            <Col span={24}>
              <Form.Item
                name="name"
                label="Nama"
                rules={[{ required: true, message: 'Nama wajib diisi!' }]}
              >
                <Input placeholder="Masukkan nama pengguna" />
              </Form.Item>
            </Col>

            
          </Row>
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item
                name="address"
                label="Address"
                rules={[{ required: true, message: 'Address is required!' }]}
              >
                <TextArea placeholder="Masukkan nama pengguna" rows={4} />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name="phone"
                label="Phone"
                rules={[{ required: false, message: 'Phone wajib diisi!' }]}
              >
                <Input placeholder="Phone" />
              </Form.Item>
            </Col>
            
          </Row>

          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name="pic_name"
                label="PIC Name"
                rules={[{ required: false, message: 'Phone wajib diisi!' }]}
              >
                <Input placeholder="PIC Name" />
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                name="pic_phone"
                label="PIC Phone"
                rules={[{ required: false, message: 'Phone wajib diisi!' }]}
              >
                <Input placeholder="PIC Phone" />
              </Form.Item>
            </Col>
            
          </Row>

          <Row gutter={16}>
            <Col span={24}>
              <Form.Item
                name="remarks"
                label="Remarks"
                rules={[{ required: false, message: 'Address is required!' }]}
              >
                <TextArea placeholder="Remarks" rows={4} />
              </Form.Item>
            </Col>
          </Row>
          </Col>

          <Col span={12}>
            <Row gutter={16}>
              <Col span={12}>
              <Form.Item label="Cover Image">
                <div style={{ textAlign: 'center' }}>
                  <Upload
                    accept="image/*"
                    showUploadList={false}
                    customRequest={handleImageUpload}
                  >
                    <Avatar
                      size={128}
                      src={coverImage || placeholderImage}
                      style={{ cursor: 'pointer', marginBottom: 16 }}
                    />
                  </Upload>
                  {coverImage && (
                    <Button
                      type="dashed"
                      onClick={() => setCoverImage(null)}
                      style={{ marginTop: 8 }}
                    >
                      Remove Image
                    </Button>
                  )}
                </div>
              </Form.Item>
              </Col>
            </Row>
            
          </Col>  
        </Row>
        
        
        <Form.Item>
          <Space>
            <Button 
              type="primary" 
              htmlType="submit" 
              icon={<SaveOutlined />} 
              loading={submitting}
            >
              {isEditing ? 'Perbarui' : 'Simpan'}
            </Button>
            <Button onClick={() => navigate('/customers')}>Batal</Button>
          </Space>
        </Form.Item>
      </Form>
    </Card>
  );
};

export default CustomerForm;