import api from './index';

const apiVersion = 'v2.0';

export const rolesAPI = {
  /**
   * Mendapatkan daftar pengguna dengan paginasi dan pencarian
   * @param {number} page - Halaman saat ini
   * @param {number} perPage - Jumlah item per halaman
   * @param {string} search - Kata kunci pencarian
   * @returns {Promise} - Promise yang menghasilkan data dan metadata pengguna
   */
  getRoles: async (page = 1, perPage = 10, search = '') => {
    try {
      const response = await api.get(`/${apiVersion}/roles`, {
        params: {
          currpage: page,
          perpage: perPage,
          search
        }
      });
      return response.data;
    } catch (error) {
      console.error('API Error - getUsers:', error);
      throw error;
    }
  }
};