import React, { useState, useEffect } from 'react';
import { 
  Table, Button, Input, Space, Popconfirm, message, Card, 
  Typography, Row, Col, Modal, Form, Spin, DatePicker,
  Badge,
  Tag
} from 'antd';
import { 
  PlusOutlined, SearchOutlined, EditOutlined, 
  DeleteOutlined, LockOutlined, ExclamationCircleOutlined 
} from '@ant-design/icons';
import {IconEye, IconReceipt} from '@tabler/icons-react'
import { useNavigate } from 'react-router-dom';
import { orderAPI } from '../../api/orderAPI';

import moment from 'moment';
import dayjs from 'dayjs';
const { Title } = Typography;
const { confirm } = Modal;
const { RangePicker } = DatePicker;


const OrderList = () => {
  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 20,
    total: 0
  });

  const [dateRange, setDateRange] = useState([dayjs(moment().subtract(7, 'days')), dayjs(moment())]);

  const [photoModalVisible, setPhotoModalVisible] = useState(false);
  const [selectedPhoto, setSelectedPhoto] = useState(null);
  
  const navigate = useNavigate();

  // Fungsi untuk memuat data user
  const fetchData = async (page = 1, pageSize = 20, search = '', dateRange = [null, null]) => {
    try {
      setLoading(true);
      const params = {
        currpage: page,
        pageSize,
        search,
        startDate: dateRange[0] ? dateRange[0].format('YYYY-MM-DD') : undefined,
        endDate: dateRange[1] ? dateRange[1].format('YYYY-MM-DD') : undefined,
      }
      const response = await orderAPI.getOrders(params);
      
      setUsers(response.data);
      setPagination({
        current: page,
        pageSize: pageSize,
        total: response.meta.total
      });
    } catch (error) {
      message.error('Gagal memuat data pengguna');
      console.error('Error fetching users:', error);
    } finally {
      setLoading(false);
    }
  };

  // Load data saat komponen dimuat
  useEffect(() => {
    fetchData(pagination.current, pagination.pageSize, searchText);
  }, []);

  // Handle perubahan pagination dan filter
  const handleTableChange = (pagination) => {
    fetchData(pagination.current, pagination.pageSize, searchText);
  };

  // Handle search
  const handleSearch = () => {
    fetchData(1, pagination.pageSize, searchText);
  };


  const handleDateRangeChange = (dates) => {
    setDateRange(dates);
    fetchData(1, pagination.pageSize, searchText, dates);
  };

  // Fungsi untuk membuka modal foto
  const showPhotoModal = (photoUrl) => {
    setSelectedPhoto(photoUrl);
    setPhotoModalVisible(true);
  };

  // Konfigurasi kolom tabel
  const columns = [
    {
        title: 'WO',
        dataIndex: 'image',
        key: 'image',
        render: (image) => (
          <img
            src={image}
            alt="WO"
            style={{ width: 50, height: 50, cursor: 'pointer' }}
            onClick={() => showPhotoModal(image)}
          />
        )
      },
    {
      title: 'Customer Name',
      dataIndex: 'customer_name',
      key: 'customer_name',
      width: 200,
      render: (name, data) => {
        
        return (
          <span>
          <span style={{ fontWeight: 'bold' }}>
            {name.toUpperCase()}
          </span>
          <br />
          {data.address}
          </span>
        );
      }
    },
    {
      title: 'Order Info',
      dataIndex: 'order_info',
      key: 'order_info',
      // render: (text) => new Date(text).toLocaleDateString('id-ID')
      render: (value, data) => {
        return (
          <span>
            <span style={{ fontWeight: 'bold' }}>
                {value}
            </span>
            <br />
            {data.last_visit_at}
          </span>
        );
      }
    },
    {
      title: "Status",
      dataIndex: 'status',
      key: 'status',
      align:'center',
      width: 100,
      render: (value, data) => {
        return (
          <Tag color={value === 'completed' ? 'green' : 'blue'}>
            {value}
            </Tag>
        );
      }
    },
    {
        title: "Payment",
        dataIndex: 'payment',
        key: 'payment',
        align:'center',
        width: 100,
        render: (value, data) => {
            return (
              <Tag color={value === 'TUNAI' ? 'green' : 'volcano'}>
                {value}
                </Tag>
            );
        }
    },
    {
        title: "Sales",
        dataIndex: 'created_by',
        key: 'created_by',
        align:'center',
        width: 200,
        render: (value, data) => {
            return (
              <span>
                <span style={{ fontWeight: 'bold' }}>
                    {value}
                </span>
                <br />
                {data.created_at}
              </span>
            );
          }
    },
    {
      title: 'Action',
      key: 'action',
      align:'center',
      width: 100,
      render: (_, record) => (
        <Space size="small">
          <Button 
            type='default'
            icon={<IconEye />} 
            onClick={() => navigate(`/work-order/view/${record.id}`)}
          >
            
          </Button>
          
          
        </Space>
      )
    }
  ];

  return (
    <div>
      <Card>
        <Row justify="space-between" align="middle" style={{ marginBottom: 16 }}>
          <Col>
            <Title level={2}>Work Order</Title>
          </Col>
          <Col>
            {/* <Button
              type="primary"
              icon={<PlusOutlined />}
              onClick={() => navigate('/users/create')}
            >
              Tambah Pengguna
            </Button> */}
          </Col>
        </Row>

        <Row style={{ marginBottom: 16 }} gutter={16}>
          <Col span={8}>
            <Input
              placeholder="Cari pengguna..."
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
              onPressEnter={handleSearch}
              suffix={<SearchOutlined onClick={handleSearch} style={{ cursor: 'pointer' }} />}
            />
          </Col>
          <Col span={8}>
            <RangePicker
              value={dateRange}
              onChange={handleDateRangeChange}
              format="DD/MM/YYYY"
            />
          </Col>
        </Row>

        <Table
          className="table-striped-rows"
          columns={columns}
          dataSource={users}
          rowKey="id"
          pagination={pagination}
          loading={loading}
          onChange={handleTableChange}
        />
      </Card>


      {/* Modal Foto */}
            <Modal
              title="Customer Photo"
              open={photoModalVisible}
              onCancel={() => setPhotoModalVisible(false)}
              footer={null}
            >
              <img src={selectedPhoto} alt="Customer Photo" style={{ width: '100%' }} />
            </Modal>
      
    </div>
  );
};

export default OrderList;