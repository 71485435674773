import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import SalesList from './SalesList';
import SalesDetail from './SalesDetail';

const UserManagement = () => {
  return (
    <Routes>
      <Route path="/" element={<SalesList />} />
      <Route path="/view/:id" element={<SalesDetail />} />
      <Route path="*" element={<Navigate to="/sales-team" />} />
    </Routes>
  );
};

export default UserManagement;