import React, { useEffect } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { ConfigProvider } from 'antd';
import LoginPage from './pages/LoginPage';
import Dashboard from './pages/Dashboard';
import AppLayout from './components/Layout/AppLayout';
import { isAuthenticated, setupAxiosInterceptors } from './utils/auth';
import '@ant-design/v5-patch-for-react-19';


import UserManagement from './pages/UserManagement';
import CustomerManagement from './pages/CustomerManagement';
import SalesVisit from './pages/SalesVisit';
import Order from './pages/OrderManagement';
import SalesTeam from './pages/SalesManagement'
import Export from './pages/ExportManagement'


// Komponen untuk route yang memerlukan autentikasi
const ProtectedRoute = ({ children }) => {
  if (!isAuthenticated()) {
    return <Navigate to="/login" />;
  }
  return children;
};

const App = () => {
  // Setup axios interceptors saat aplikasi dimulai
  useEffect(() => {
    setupAxiosInterceptors();
  }, []);

  return (
    <ConfigProvider
    theme={{
      token: {
        // Seed Token
        // colorPrimary: '#00b96b',
        // borderRadius: 8,

        // // Alias Token
        // colorBgContainer: '#f4f6f9',

        
      },
      components: {
        Table: {
          headerBg: '#595959',
          headerColor: '#fff',
        },
        Card: {
          headerBg: '#fff',
          actionsBg: '#f0f2f5',
          extraColor: '#fff',
        }
      },
    }}
  >
<Routes>
      <Route path="/login" element={<LoginPage />} />
      <Route path="/" element={<Navigate to="/dashboard" />} />
      
      <Route
        path="/"
        element={
          <ProtectedRoute>
            <AppLayout />
          </ProtectedRoute>
        }
      >
        <Route path="/dashboard" element={<Dashboard />} />
        {/* Route tambahan bisa ditambahkan di sini */}
        {/* <Route path="/users" element={<div>Halaman Users (dalam pengembangan)</div>} /> */}
        <Route path="/settings" element={<div>Halaman Settings (dalam pengembangan)</div>} />
        <Route path="/users/*" element={<UserManagement />} />
        <Route path="/customers/*" element={<CustomerManagement />} />
        <Route path="/sales-visit/*" element={<SalesVisit />} />
        <Route path="/work-order/*" element={<Order />} />
        <Route path="/sales-team/*" element={<SalesTeam />} />
        <Route path="/export-logs/*" element={<Export />} />
        
        
      </Route>
      
      <Route path="*" element={<Navigate to="/dashboard" />} />
    </Routes>
  </ConfigProvider>
    
  );
};

export default App;