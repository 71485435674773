import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Card, Typography, Button, Spin, message, Tabs, Descriptions, Table, Row, Col, Tag } from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { orderAPI } from '../../api/orderAPI';

const { Title } = Typography;
const { TabPane } = Tabs;

const OrderDetail = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [order, setOrder] = useState(null);
  const [customerId, setCustomerId] = useState(null);
  const [orderHistory, setOrderHistory] = useState([]);

  useEffect(() => {
    const fetchDetail = async () => {
      try {
        const response = await orderAPI.getOrder(id);

        setCustomerId(response.customer.id);
        setOrder(response);
      } catch (error) {
        message.error('Gagal memuat data pelanggan');
        console.error('Error fetching customer:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchDetail();
  }, [id])

  useEffect(() => {
    if (customerId) {

        const fetchOrderHistory = async () => {
            try {
                const response = await orderAPI.getOrderHistory(customerId);
                setOrderHistory(response);
            } catch (error) {
                message.error('Gagal memuat data riwayat pesanan');
                console.error('Error fetching order history:', error);
            }
        };

        fetchOrderHistory();
    }
  }, [customerId]);

  if (loading) {
    return <Spin size="large" />;
  }

  if (!order) {
    return <div>Order not found</div>;
  }


  const orderHistoryColumns = [
    {
      title: 'No Order',
      dataIndex: 'order_no',
      key: 'order_no',
    },
    {
      title: 'Order Info',
      dataIndex: 'order_info',
      key: 'order_info',
      render : (value) => {
        return <span style={{ whiteSpace: 'pre-wrap' }}>{value}</span>
      }
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (value, data) => {
        return (
          <Tag color={value === 'completed' ? 'green' : 'blue'}>
            {value}
            </Tag>
        );
      }
    },
    {
      title: 'Payment',
      dataIndex: 'payment',
      key: 'payment',
      render: (value, data) => {
        return (
          <Tag color={value === 'TUNAI' ? 'green' : 'volcano'}>
            {value}
            </Tag>
        );
      }
    },
    {
      title: 'Created',
      dataIndex: 'created_by',
      key: 'created_by',
      align:'center',
      render: (value, row) => {
        return (
          <span>
            <span style={{ fontWeight: 'bold' }}>
                {value}
            </span>
            <br />
            {row.created_at}
          </span>
        );
      }
    },
  ];

  return (
    <Card>
      <Button
        type="link"
        icon={<ArrowLeftOutlined />}
        onClick={() => navigate('/work-order')}
      >
        Back to Customer List
      </Button>
      <Title level={2}>Order Detail</Title>
      
      <Row gutter={16}>
        {/* Order Details on the Left */}
        <Col span={12}>
            <Descriptions 
                size='small' bordered column={1}>
                <Descriptions.Item label="No Order">{order.no_order}</Descriptions.Item>
                <Descriptions.Item label="Order Created">{order.created_at}</Descriptions.Item>
                <Descriptions.Item label="Payment">
                    {order.payment_method}
                    </Descriptions.Item>
                <Descriptions.Item label="Status">{order.status}</Descriptions.Item>

                <Descriptions.Item label="Order Info">
                    <span style={{ whiteSpace: 'pre-wrap' }}>{order.order_info}</span>
                    </Descriptions.Item>
                <Descriptions.Item label="WO Photo">
                    <img
                        alt='Work Order'
                        src={order.image || 'https://via.placeholder.com/100'}
                        style={{ width: '100px', borderRadius: 8 }}/>
                </Descriptions.Item>
            </Descriptions>
        </Col>

        {/* Customer Details on the Right */}
        <Col span={12}>
              <Col span={24}>
                <Descriptions bordered column={1}>
                  <Descriptions.Item label="Name">{order?.customer?.name}</Descriptions.Item>
                  <Descriptions.Item label="Address">{order?.customer?.address}</Descriptions.Item>
                  <Descriptions.Item label="Phone">{order?.customer?.phone}</Descriptions.Item>
                  <Descriptions.Item label="PIC">{order?.customer?.pic_name}</Descriptions.Item>
                  <Descriptions.Item label="Phone">{order?.customer?.pic_phone}</Descriptions.Item>
                </Descriptions>
              </Col>
          
        </Col>
      </Row>
      <Tabs defaultActiveKey="1" style={{ marginTop: 20 }}>
        {/* <TabPane tab="Sales Visit History" key="1">
          <Table
            columns={salesVisitColumns}
            dataSource={salesVisits}
            rowKey="id"
            pagination={false}
          />
        </TabPane> */}
        <TabPane tab="Order History" key="2">
          <Table
            columns={orderHistoryColumns}
            dataSource={orderHistory}
            rowKey="order_id"
            pagination={false}
          />
        </TabPane>
      </Tabs>
    </Card>
  );
};

export default OrderDetail;