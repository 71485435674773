import React, { useState, useEffect } from 'react';
import { 
  Form, Input, Button, Card, Select, Switch, 
  Row, Col, message, Spin, Typography, Space 
} from 'antd';
import { ArrowLeftOutlined, SaveOutlined } from '@ant-design/icons';
import { useNavigate, useParams } from 'react-router-dom';
import { userAPI } from '../../api/userAPI';
import { rolesAPI  } from '../../api/rolesAPI';

const { Title } = Typography;
const { Option } = Select;

const UserForm = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const { id } = useParams();
  const isEditing = !!id;

  const [loading, setLoading] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [roles, setRoles] = useState([]);

  // Memuat data user untuk mode edit
  useEffect(() => {
    if (isEditing) {
      const fetchUser = async () => {
        try {
          setLoading(true);
          const user = await userAPI.getUser(id);
          form.setFieldsValue({
            name: user.name,
            username: user.username,
            // role_id: user.role_id,
            role: user?.role?.id ?? '',
            status: user.status,
            phone: user.phone,
          });
        } catch (error) {
          message.error('Gagal memuat data pengguna');
          console.error('Error fetching user:', error);
          navigate('/users');
        } finally {
          setLoading(false);
        }
      };
      
      fetchUser();
    }
  }, [id, form, navigate, isEditing]);

  useEffect(() => {
    const fetchRoles = async () => {
      try {
        const response = await rolesAPI.getRoles(1, 0);
        setRoles(response.data);
      } catch (error) {
        message.error('Gagal memuat data role');
        console.error('Error fetching roles:', error);
      }
    };

    fetchRoles();
  }, []);

  // Handle submit form
  const handleSubmit = async (values) => {
    try {
      setSubmitting(true);
      if (isEditing) {
        await userAPI.updateUser(id, values);
        message.success('Pengguna berhasil diperbarui');
      } else {
        await userAPI.createUser(values);
        message.success('Pengguna berhasil dibuat');
      }
      navigate('/users');
    } catch (error) {
      const errorMsg = error.response?.data?.message || 'Terjadi kesalahan';
      message.error(errorMsg);
      console.error('Error saving user:', error);
    } finally {
      setSubmitting(false);
    }
  };

  if (loading) {
    return (
      <div style={{ textAlign: 'center', padding: '50px' }}>
        <Spin size="large" />
        <p>Memuat data pengguna...</p>
      </div>
    );
  }

  return (
    <Card>
      <Row justify="space-between" align="middle" style={{ marginBottom: 24 }}>
        <Col>
          <Title level={2}>{isEditing ? 'Edit User' : 'Create User'}</Title>
        </Col>
        <Col>
          <Button 
            icon={<ArrowLeftOutlined />} 
            onClick={() => navigate('/users')}
          >
            Back
          </Button>
        </Col>
      </Row>

      <Form
        form={form}
        layout="vertical"
        onFinish={handleSubmit}
        initialValues={{ active: true, role: 'user' }}
      >
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name="name"
              label="Nama"
              rules={[{ required: true, message: 'Nama wajib diisi!' }]}
            >
              <Input placeholder="Masukkan nama pengguna" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name="username"
              label="Username"
              rules={[
                { required: true, message: 'Username is required!' },
                { type: 'email', message: 'Email format is incorrect!' }
              ]}
            >
              <Input placeholder="Masukkan email pengguna" />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name="role"
              label="Role"
              rules={[{ required: true, message: 'Role wajib dipilih!' }]}
            >
              <Select placeholder="Select Role">
                {roles.map(role => (
                  <Option key={role.id} value={role.id}>{role.name}</Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={8}>
            <Form.Item
              name="phone"
              label="Phone"
              // rules={[{ required: true, message: 'Nama wajib diisi!' }]}
            >
              <Input placeholder="Masukkan nama pengguna" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={4}>
            <Form.Item
              name="status"
              label="Status"
              valuePropName="checked"
            >
              <Switch 
                checkedChildren="Active" 
                unCheckedChildren="Inactive" 
              />
            </Form.Item>
          </Col>
        </Row>

        {!isEditing && (
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name="password"
                label="Password"
                rules={[
                  { required: true, message: 'Password wajib diisi!' },
                  { min: 6, message: 'Password minimal 6 karakter' }
                ]}
              >
                <Input.Password placeholder="Masukkan password" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="password_confirmation"
                label="Konfirmasi Password"
                dependencies={['password']}
                rules={[
                  { required: true, message: 'Konfirmasi password wajib diisi!' },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue('password') === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(new Error('Password tidak cocok!'));
                    },
                  }),
                ]}
              >
                <Input.Password placeholder="Konfirmasi password" />
              </Form.Item>
            </Col>
          </Row>
        )}

        <Form.Item>
          <Space>
            <Button 
              type="primary" 
              htmlType="submit" 
              icon={<SaveOutlined />} 
              loading={submitting}
            >
              {isEditing ? 'Perbarui' : 'Simpan'}
            </Button>
            <Button onClick={() => navigate('/users')}>Batal</Button>
          </Space>
        </Form.Item>
      </Form>
    </Card>
  );
};

export default UserForm;