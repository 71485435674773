import React from 'react';
import { Layout, Menu, Button, Typography, Avatar, Dropdown } from 'antd';
// import { LogoutOutlined, UserOutlined } from '@ant-design/icons';
import { IconPower, IconUser } from '@tabler/icons-react';
import { useNavigate } from 'react-router-dom';
import { logout, getUser } from '../../utils/auth';

const { Header } = Layout;
const { Text } = Typography;

const AppHeader = () => {
  const navigate = useNavigate();
  const user = getUser();

  const handleLogout = () => {
    logout();
    navigate('/login');
  };

  const items = [
    {
      key: '1',
      label: (
        <div style={{ padding: '8px 0' }}>
          <Text strong>{user?.name}</Text>
          <div>{user?.role_id}</div>
        </div>
      ),
    },
    {
      key: '2',
      label: 'Logout',
      icon: <IconPower />,
      onClick: handleLogout,
    },
  ];

  return (
    <Header className="site-header" style={{
      position: 'sticky',
      top: 0,
      zIndex: 1,
      width: '100%',
      display: 'flex',
      alignItems: 'center',
    }}>
      <div className="logo">CMS</div>
      <div>
        <Dropdown menu={{ items }} placement="bottomRight">
          <Button type="text" icon={<Avatar icon={<IconUser />} />} style={{ color: 'white' }}>
            {user?.name}
          </Button>
        </Dropdown>
      </div>
    </Header>
  );
};

export default AppHeader;