import React from 'react';
import { Layout, Menu } from 'antd';
import { IconReportMoney, IconHome, IconUsersGroup, IconUserPin, IconAdjustmentsAlt, IconUsers, IconFileArrowRight } from '@tabler/icons-react';
import { useNavigate, useLocation } from 'react-router-dom';

const { Sider } = Layout;

const AppSidebar = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const items = [
    {
      key: '/dashboard',
      icon: <IconHome />,
      label: 'Dashboard',
      onClick: () => navigate('/dashboard'),
    },
    {
      key: '/users',
      icon: <IconUsers  />,
      label: 'User Management',
      onClick: () => navigate('/users'),
    },

    {
      key: '/sales-team',
      icon: <IconUsers  />,
      label: 'Sales Team',
      onClick: () => navigate('/sales-team'),
    },
    {
      key: '/customers',
      icon: <IconUsersGroup  />,
      label: 'Customer Management',
      onClick: () => navigate('/customers'),
    },
    {
      key: '/sales-visit',
      icon: <IconUserPin  />,
      label: 'Sales Visit',
      onClick: () => navigate('/sales-visit'),
    },
    {
      key: '/work-order',
      icon: <IconReportMoney  />,
      label: 'Work Order',
      onClick: () => navigate('/work-order'),
    },
    {
      key: '/settings',
      icon: <IconAdjustmentsAlt />,
      label: 'Settings',
      onClick: () => navigate('/settings'),
    },

    {
      key: '/export-logs',
      icon: <IconFileArrowRight />,
      label: 'Export Logs',
      onClick: () => navigate('/export-logs'),
    },
    
  ];

  return (
    <Sider width={200} theme="dark" breakpoint="lg" collapsible style={{
      overflow: 'auto',
      height: '100vh',
      position: 'sticky',
      insetInlineStart: 0,
      top: 0,
      bottom: 0,
      // scrollbarWidth: 'thin',
      scrollbarGutter: 'stable',
    }}>
      <div className="logo">Admin</div>
      <Menu
        theme="dark"
        mode="inline"
        defaultSelectedKeys={[location.pathname]}
        style={{ height: '100%', borderRight: 0 }}
        items={items}
      />
    </Sider>
  );
};

export default AppSidebar;