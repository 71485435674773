import React, { useState, useEffect } from 'react';
import { 
  Table, Button, Input, Space, Popconfirm, message, Card, 
  Typography, Row, Col, Modal, Form, Spin, DatePicker,
  Badge,
  Tag
} from 'antd';
import { 
  SearchOutlined, FilePdfOutlined, FileExcelOutlined, FileWordOutlined, FileTextOutlined, FileUnknownOutlined,
  ExclamationCircleOutlined
} from '@ant-design/icons';
import {IconDownload, IconTrash} from '@tabler/icons-react'
import { useNavigate } from 'react-router-dom';
import { exportAPI } from '../../api/exportAPI.js';

import moment from 'moment';
import dayjs from 'dayjs';
const { Title } = Typography;
const { confirm } = Modal;
const { RangePicker } = DatePicker;


const ExportList = () => {
  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 20,
    total: 0
  });

  const [dateRange, setDateRange] = useState([dayjs(moment().subtract(7, 'days')), dayjs(moment())]);

  const [photoModalVisible, setPhotoModalVisible] = useState(false);
  const [selectedPhoto, setSelectedPhoto] = useState(null);
  
  const navigate = useNavigate();

  // Fungsi untuk memuat data user
  const fetchData = async (page = 1, pageSize = 20, search = '', dateRange = [null, null]) => {
    try {
      setLoading(true);
      const params = {
        currpage: page,
        pageSize,
        search,
        startDate: dateRange[0] ? dateRange[0].format('YYYY-MM-DD') : undefined,
        endDate: dateRange[1] ? dateRange[1].format('YYYY-MM-DD') : undefined,
      }
      const response = await exportAPI.getExports(params);
      
      setUsers(response.data);
      setPagination({
        current: page,
        pageSize: pageSize,
        total: response.meta.total
      });
    } catch (error) {
      message.error('Gagal memuat data pengguna');
      console.error('Error fetching users:', error);
    } finally {
      setLoading(false);
    }
  };

  // Load data saat komponen dimuat
  useEffect(() => {
    fetchData(pagination.current, pagination.pageSize, searchText);
  }, []);

  // Handle perubahan pagination dan filter
  const handleTableChange = (pagination) => {
    fetchData(pagination.current, pagination.pageSize, searchText);
  };

  // Handle search
  const handleSearch = () => {
    fetchData(1, pagination.pageSize, searchText);
  };


  const handleDateRangeChange = (dates) => {
    setDateRange(dates);
    fetchData(1, pagination.pageSize, searchText, dates);
  };

  const handleDeleteRequest = async (id) => {
    try {
      await exportAPI.deleteExport(id);
      message.success('Data berhasil dihapus');
      fetchData();
    } catch (error) {
      message.error('Gagal menghapus data');
      console.error('Error deleting data:', error);
    }
  };

  const mimeTypeToIcon = {
    'application/pdf': <FilePdfOutlined style={{ color: 'red', fontSize: '20px' }} />,
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': <FileExcelOutlined style={{ color: 'green', fontSize: '20px' }} />,
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document': <FileWordOutlined style={{ color: 'blue', fontSize: '20px' }} />,
    'text/plain': <FileTextOutlined style={{ color: 'gray', fontSize: '20px' }} />,
  };

  const getFileIcon = (mimeType) => {
    return mimeTypeToIcon[mimeType] || <FileUnknownOutlined style={{ color: 'gray', fontSize: '20px' }} />;
  };

  // Konfigurasi kolom tabel
  const columns = [
    {
      title: 'Export Data',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Export Info',
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: "File Info",
      dataIndex: 'file_size',
      key: 'file_size',
      align:'center',
      width: 100,
      render: (mimeType, data) => {
        return (mimeType) ?
         (
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            {getFileIcon(data.file_ext)}
            <Tag color={'blue'}>
             {data.file_size}
            </Tag>
          </div>
        ) : '-';
      }
    },
    
    {
        title: "Exported By",
        dataIndex: 'created_by',
        key: 'created_by',
        align:'center',
        width: 200,
        render: (value, data) => {
            return (
              <span>
                <span style={{ fontWeight: 'bold' }}>
                    {value}
                </span>
                <br />
                {data.created_at}
              </span>
            );
          }
    },
    {
      title: 'Action',
      key: 'action',
      align:'center',
      width: 100,
      render: (_, record) => (
        <Space size="small">
      <Button 
        type='link'
        href={record.file_path} // The URL of the file
        target="_blank" // Open in a new tab
        rel="noopener noreferrer" // Security best practice
        color='primary'
        icon={<IconDownload />}
        variant='outlined'
      >
        
      </Button>
      <Button 
        type='link'
        target="_blank" // Open in a new tab
        rel="noopener noreferrer" // Security best practice
        color='primary'
        icon={<IconTrash />}
        variant='outlined'
        onClick={() => {
          confirm({
            title: 'Do you want to delete these items?',
            icon: <ExclamationCircleOutlined />,
            content: 'When clicked the OK button, this dialog will be closed after 1 second',
            onOk() {
              handleDeleteRequest(record.id);
            },
            onCancel() {
              console.log('Cancel');
            },
          });
        }}
      >
        
      </Button>
    </Space>
      )
    }
  ];

  return (
    <div>
      <Card>
        <Row justify="space-between" align="middle" style={{ marginBottom: 16 }}>
          <Col>
            <Title level={2}>Work Order</Title>
          </Col>
          <Col>
            {/* <Button
              type="primary"
              icon={<PlusOutlined />}
              onClick={() => navigate('/users/create')}
            >
              Tambah Pengguna
            </Button> */}
          </Col>
        </Row>

        <Row style={{ marginBottom: 16 }} gutter={16}>
          <Col span={8}>
            <Input
              placeholder="Cari pengguna..."
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
              onPressEnter={handleSearch}
              suffix={<SearchOutlined onClick={handleSearch} style={{ cursor: 'pointer' }} />}
            />
          </Col>
          <Col span={8}>
            <RangePicker
              value={dateRange}
              onChange={handleDateRangeChange}
              format="DD/MM/YYYY"
            />
          </Col>
        </Row>

        <Table
          className="table-striped-rows"
          columns={columns}
          dataSource={users}
          rowKey="id"
          pagination={pagination}
          loading={loading}
          onChange={handleTableChange}
        />
      </Card>


    </div>
  );
};

export default ExportList;