import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import CustomerList from './CustomerList';
import CustomerForm from './CustomerForm';
import CustomerDetail from './CustomerDetail';

const CustomerManagement = () => {
  return (
    <Routes>
      <Route path="/" element={<CustomerList />} />
      <Route path="/create" element={<CustomerForm />} />
      <Route path="/edit/:id" element={<CustomerForm />} />
      <Route path="/view/:id" element={<CustomerDetail />} />
      <Route path="*" element={<Navigate to="/customers" />} />
    </Routes>
  );
};

export default CustomerManagement;