import api from './index';

const apiVersion = 'v2.0';

export const salesVisitAPI = {
  /**
   * Mendapatkan daftar pengguna dengan paginasi dan pencarian
   * @param {number} page - Halaman saat ini
   * @param {number} perPage - Jumlah item per halaman
   * @param {string} search - Kata kunci pencarian
   * @returns {Promise} - Promise yang menghasilkan data dan metadata pengguna
   */
  getVisits: async (params) => {
    try {
      const response = await api.get(`/${apiVersion}/sales-visit`, {
        params: params
      });
      return response.data;
    } catch (error) {
      console.error('API Error - getUsers:', error);
      throw error;
    }
  },

  /**
   * Mendapatkan data pengguna berdasarkan ID
   * @param {number|string} id - ID pengguna
   * @returns {Promise} - Promise yang menghasilkan data pengguna
   */
  getVisit: async (id) => {
    try {
      const response = await api.get(`${apiVersion}/sales-visit/${id}`);
      return response.data.data;
    } catch (error) {
      console.error(`API Error - getUser ${id}:`, error);
      throw error;
    }
  },

  getVisitBySales: async (salesId, params) => {
    try {
      const response = await api.get(`/${apiVersion}/sales-visit/${salesId}/sales`, {
        params: params
      });
      return response.data;
    } catch (error) {
      console.error('API Error - getUsers:', error);
      throw error;
    }
  },

  getVisitByCustomer: async (shopId, params) => {
    try {
      const response = await api.get(`/${apiVersion}/sales-visit/${shopId}/customer`, {
        params: params
      });
      return response.data;
    } catch (error) {
      console.error('API Error - getUsers:', error);
      throw error;
    }
  },

  getLeaderBoard: async () => {
    try {
      const response = await api.get(`/${apiVersion}/sales-visit/leader-board`);
      return response.data;
    } catch (error) {
      console.error('API Error - getUsers:', error);
      throw error;
    }
  },
  
  exportPDF: async (id) => {
    try {
      const response = await api.get(`${apiVersion}/sales-visit/${id}/export-pdf`, {
        responseType: 'blob'
      });

      const blob = new Blob([response.data], { type: 'application/pdf' });
      const url = window.URL.createObjectURL(blob);

      return url;
    } catch (error) {
      console.error(`API Error - exportVisit ${id}:`, error);
      throw error;
    }
  },

  exportMultiVisitPDF: async (params) => {
    try {
      const response = await api.post(`${apiVersion}/sales-visit/exports`, params);

      console.log('RES', response);
      return response;
    } catch (error) {
      console.error(`API Error - exportVisit:`, error);
      throw error;
    }
  }
};