import React, { useState, useEffect } from 'react';
import { 
  Table, Button, Input, Space, Popconfirm, message, Card, 
  Typography, Row, Col, Modal, Form, Spin, DatePicker
} from 'antd';
import { 
  PlusOutlined, SearchOutlined, EditOutlined, 
  DeleteOutlined, LockOutlined, ExclamationCircleOutlined 
} from '@ant-design/icons';
import {IconEye, IconReceipt} from '@tabler/icons-react'
import { useNavigate } from 'react-router-dom';
import { salesVisitAPI } from '../../api/salesVisitAPI';
import moment from 'moment';
import dayjs from 'dayjs';
const { Title } = Typography;
const { confirm } = Modal;
const { RangePicker } = DatePicker;


const SalesVisitList = () => {
  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 20,
    total: 0
  });

  const [orderModalVisible, setOrderModalVisible] = useState(false);
  const [selectedOrders, setSelectedOrders] = useState([]);
  const [dateRange, setDateRange] = useState([dayjs(moment().subtract(7, 'days')), dayjs(moment())]);
  
  const navigate = useNavigate();

  // Fungsi untuk memuat data user
  const fetchUsers = async (page = 1, pageSize = 20, search = '', dateRange = [null, null]) => {
    try {
      setLoading(true);
      const params = {
        page,
        pageSize,
        search,
        startDate: dateRange[0] ? dateRange[0].format('YYYY-MM-DD') : undefined,
        endDate: dateRange[1] ? dateRange[1].format('YYYY-MM-DD') : undefined,
      }
      const response = await salesVisitAPI.getVisits(params);
      
      setUsers(response.data);
      setPagination({
        current: page,
        pageSize: pageSize,
        total: response.meta.total
      });
    } catch (error) {
      message.error('Gagal memuat data pengguna');
      console.error('Error fetching users:', error);
    } finally {
      setLoading(false);
    }
  };

  // Load data saat komponen dimuat
  useEffect(() => {
    fetchUsers(pagination.current, pagination.pageSize, searchText);
  }, []);

  // Handle perubahan pagination dan filter
  const handleTableChange = (pagination) => {
    fetchUsers(pagination.current, pagination.pageSize, searchText);
  };

  // Handle search
  const handleSearch = () => {
    fetchUsers(1, pagination.pageSize, searchText);
  };

  // Handle show order modal
  const showOrderModal = (orders) => {
    setSelectedOrders(orders);
    setOrderModalVisible(true);
  };

  // Handle close order modal
  const handleOrderModalClose = () => {
    setOrderModalVisible(false);
    setSelectedOrders([]);
  };

  const handleDateRangeChange = (dates) => {
    setDateRange(dates);
    fetchUsers(1, pagination.pageSize, searchText, dates);
  };

  // Konfigurasi kolom tabel
  const columns = [
    {
      title: 'Nama',
      dataIndex: 'name',
      key: 'name',
      // sorter: true,
      render: (name, data) => {
        
        return (
          <span>
          <span style={{ fontWeight: 'bold' }}>
            {name.toUpperCase()}
          </span>
          <br />
          {data.address}
          </span>
        );
      }
    },
    {
      title: 'Sales',
      dataIndex: 'last_visit_by',
      key: 'last_visit_by',
      width: 150,
      render: (value, data) => {
        
        return (
          <span>
          <span style={{ fontWeight: 'bold' }}>
            {value}
          </span>
          <br />
          {data.last_visit_at}
          </span>
        );
      }
    },
    {
      title: "Duration (Min)",
      dataIndex: 'durasi',
      key: 'durasi',
      align:'center',
      width: 100
    },
    {
      title: "Orders",
      dataIndex: 'orders',
      key: 'orders',
      align:'center',
      width: 100,
      render: (_, record) => {
        if(record.orders.length > 0){
          return (
          <Space size="small">
            <Button 
              type='default'
              color='danger'
              variant="outlined"
              icon={<IconReceipt />} 
              onClick={() => showOrderModal(record.orders)}
            >
            </Button>
          </Space>)
      }
    }
    },
    {
      title: 'Action',
      key: 'action',
      align:'center',
      width: 100,
      render: (_, record) => (
        <Space size="small">
          <Button 
            type='default'
            icon={<IconEye />} 
            onClick={() => navigate(`/sales-visit/view/${record.id}`)}
          >
            
          </Button>
          
          
        </Space>
      )
    }
  ];

  return (
    <div>
      <Card>
        <Row justify="space-between" align="middle" style={{ marginBottom: 16 }}>
          <Col>
            <Title level={2}>Sales Visit</Title>
          </Col>
          <Col>
            {/* <Button
              type="primary"
              icon={<PlusOutlined />}
              onClick={() => navigate('/users/create')}
            >
              Tambah Pengguna
            </Button> */}
          </Col>
        </Row>

        <Row style={{ marginBottom: 16 }} gutter={16}>
          <Col span={8}>
            <Input
              placeholder="Cari pengguna..."
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
              onPressEnter={handleSearch}
              suffix={<SearchOutlined onClick={handleSearch} style={{ cursor: 'pointer' }} />}
            />
          </Col>
          <Col span={8}>
            <RangePicker
              value={dateRange}
              onChange={handleDateRangeChange}
              format="DD/MM/YYYY"
            />
          </Col>
        </Row>

        <Table
          className="table-striped-rows"
          columns={columns}
          dataSource={users}
          rowKey="id"
          pagination={pagination}
          loading={loading}
          onChange={handleTableChange}
        />
      </Card>


      {/* Modals Information Order */}
      <Modal
        title="Order Details"
        
        visible={orderModalVisible}
        onCancel={handleOrderModalClose}
        footer={null}
        width={800}
      >
        <Table
          columns={[
            { 
              title: 'Order Info', 
              dataIndex: 'order_info', 
              key: 'order_info',
              render: (text) => (
                <span style={{ whiteSpace: 'pre-wrap' }}>{text}</span>
              )
            },
            { title: 'Product', dataIndex: 'payment_method', key: 'payment_method' },
          ]}
          dataSource={selectedOrders}
          rowKey="order_id"
          pagination={false}
        />
      </Modal>
      
    </div>
  );
};

export default SalesVisitList;