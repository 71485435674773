import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import ExportList from './ExportList';

const SalesVisit = () => {
  return (
    <Routes>
      <Route path="/" element={<ExportList />} />
      <Route path="*" element={<Navigate to="/export-logs" />} />
    </Routes>
  );
};

export default SalesVisit;