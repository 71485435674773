import api from './index';

const apiVersion = 'v2.0';
export const salesAPI = {
  /**
   * Mendapatkan daftar pengguna dengan paginasi dan pencarian
   * @param {number} page - Halaman saat ini
   * @param {number} perPage - Jumlah item per halaman
   * @param {string} search - Kata kunci pencarian
   * @returns {Promise} - Promise yang menghasilkan data dan metadata pengguna
   */
  getSales: async (page = 1, perPage = 10, search = '') => {
    try {
      const response = await api.get(`/${apiVersion}/sales`, {
        params: {
          currpage: page,
          perpage: perPage,
          search
        }
      });
      return response.data;
    } catch (error) {
      console.error('API Error - getSales:', error);
      throw error;
    }
  },

  /**
   * Mendapatkan data pengguna berdasarkan ID
   * @param {number|string} id - ID pengguna
   * @returns {Promise} - Promise yang menghasilkan data pengguna
   */
  getSalesById: async (id) => {
    try {
      const response = await api.get(`/${apiVersion}/sales/${id}`);
      return response.data.data;
    } catch (error) {
      console.error(`API Error - getSalesById ${id}:`, error);
      throw error;
    }
  },

};