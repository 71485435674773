import React, { useState, useEffect } from 'react';
import { 
  Table, Button, Input, Space, Popconfirm, message, Card, 
  Typography, Row, Col, Modal, Form, Spin 
} from 'antd';
import { 
  PlusOutlined, SearchOutlined, EditOutlined, 
  DeleteOutlined, LockOutlined, ExclamationCircleOutlined 
} from '@ant-design/icons';
import {IconUserCheck, IconUserCancel, IconEye} from '@tabler/icons-react'
import { useNavigate } from 'react-router-dom';
import { salesAPI } from '../../api/salesAPI';

const { Title } = Typography;
const { confirm } = Modal;

const SalesList = () => {
  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0
  });
  
  const navigate = useNavigate();

  // Fungsi untuk memuat data user
  const fetchUsers = async (page = 1, pageSize = 10, search = '') => {
    try {
      setLoading(true);
      const response = await salesAPI.getSales(page, pageSize, search);
      
      setUsers(response.data);
      setPagination({
        current: page,
        pageSize: pageSize,
        total: response.meta.total
      });
    } catch (error) {
      message.error('Failed load user data');
      console.error('Error fetching users:', error);
    } finally {
      setLoading(false);
    }
  };

  // Load data saat komponen dimuat
  useEffect(() => {
    fetchUsers(pagination.current, pagination.pageSize, searchText);
  }, []);

  // Handle perubahan pagination dan filter
  const handleTableChange = (pagination) => {
    fetchUsers(pagination.current, pagination.pageSize, searchText);
  };

  // Handle search
  const handleSearch = () => {
    fetchUsers(1, pagination.pageSize, searchText);
  };


  // Konfigurasi kolom tabel
  const columns = [
    {
      title: 'Nama',
      dataIndex: 'name',
      key: 'name',
      // sorter: true
    },
    {
      title: 'Phone',
      dataIndex: 'phone',
      key: 'phone'
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      align: 'center',
      render: (status) => (
        <span style={{ 
          color: status == 1 ? 'green' : 'red',
          fontWeight: 'bold'
        }}>
          {status == 1 ? (
            (<IconUserCheck/>)
          ) : (<IconUserCancel/>)}
        </span>
      )
    },
    {
      title: 'Action',
      key: 'action',
      align:'center',
      width: 200,
      render: (_, record) => (
        <Space size="small">
          <Button 
            type='default'
            icon={<IconEye />} 
            onClick={() => navigate(`/sales-team/view/${record.id}`)}
          >
            
          </Button>
          
          
        </Space>
      )
    }
  ];

  return (
    <div>
      <Card>
        <Row justify="space-between" align="middle" style={{ marginBottom: 16 }}>
          <Col>
            <Title level={2}>Sales Team</Title>
          </Col>
        </Row>

        <Row style={{ marginBottom: 16 }}>
          <Col span={8}>
            <Input
              placeholder="Search User..."
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
              onPressEnter={handleSearch}
              suffix={<SearchOutlined onClick={handleSearch} style={{ cursor: 'pointer' }} />}
            />
          </Col>
        </Row>

        <Table
          className="table-striped-rows"
          columns={columns}
          dataSource={users}
          rowKey="id"
          pagination={pagination}
          loading={loading}
          onChange={handleTableChange}
        />
      </Card>
    </div>
  );
};

export default SalesList;