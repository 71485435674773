import React, { useEffect } from 'react';
import { Typography } from 'antd';
import { useNavigate } from 'react-router-dom';
import LoginForm from '../components/Login/LoginForm';
import { isAuthenticated } from '../utils/auth';

const { Title } = Typography;

const LoginPage = () => {
  const navigate = useNavigate();

  useEffect(() => {
    // Redirect ke dashboard jika sudah login
    if (isAuthenticated()) {
      navigate('/dashboard');
    }
  }, [navigate]);

  return (
    <div className="login-page">
      <div className="login-container">
        <div className="login-title">
          <Title level={2}>Admin Dashboard</Title>
        </div>
        <LoginForm />
      </div>
    </div>
  );
};

export default LoginPage;