import React from 'react';
import { Layout, theme } from 'antd';
import { Outlet, useNavigate } from 'react-router-dom';
import AppHeader from './AppHeader';
import AppSidebar from './AppSidebar';
import { isAuthenticated } from '../../utils/auth';

const { Content, Footer } = Layout;

const AppLayout = () => {
  const navigate = useNavigate();
  const { token } = theme.useToken();

  React.useEffect(() => {
    // Redirect ke login jika belum login
    if (!isAuthenticated()) {
      navigate('/login');
    }
  }, [navigate]);

  if (!isAuthenticated()) {
    return null;
  }

  return (
    <Layout style={{ minHeight: '100vh' }}>
      <AppHeader />
      <Layout>
        <AppSidebar />
        <Layout 
        // style={{ padding: '0 24px 24px' }}
        >
          <Content
            style={{
              // margin: '24px 16px',
              padding: 24,
              // background: token.colorBgContainer,
              // borderRadius: token.borderRadiusLG,
              // headerBg: token.headerBg,
              // actionsBg: token.actionsBg,
              // extraColor: token.extraColor,
            }}
          >
            <Outlet />
          </Content>
          <Footer style={{ textAlign: 'center' }}>
            RMS Admin Dashboard ©{new Date().getFullYear()} Created with ❤️ by RMS Team
          </Footer>
        </Layout>
      </Layout>
    </Layout>
  );
};

export default AppLayout;