import api from './index';

const apiVersion = 'v2.0';

export const customerAPI = {
  /**
   * Mendapatkan daftar pengguna dengan paginasi dan pencarian
   * @param {number} page - Halaman saat ini
   * @param {number} perPage - Jumlah item per halaman
   * @param {string} search - Kata kunci pencarian
   * @returns {Promise} - Promise yang menghasilkan data dan metadata pengguna
   */
  getCustomers: async (page = 1, perPage = 10, search = '') => {
    try {
      const response = await api.get(`/${apiVersion}/customer`, {
        params: {
          currpage: page,
          perpage: perPage,
          search
        }
      });
      console.log('RES', response)
      return response.data;
    } catch (error) {
      console.error('API Error - getUsers:', error);
      throw error;
    }
  },

  /**
   * Mendapatkan data pengguna berdasarkan ID
   * @param {number|string} id - ID pengguna
   * @returns {Promise} - Promise yang menghasilkan data pengguna
   */
  getCustomer: async (id) => {
    try {
      const response = await api.get(`/${apiVersion}/customer/${id}`);
      return response.data.data;
    } catch (error) {
      console.error(`API Error - getUser ${id}:`, error);
      throw error;
    }
  },

  /**
   * Membuat pengguna baru
   * @param {Object} userData - Data pengguna baru
   * @returns {Promise} - Promise yang menghasilkan data pengguna yang dibuat
   */
  createCustomer: async (userData) => {
    try {
      const response = await api.post('/customer', userData);
      return response.data.data;
    } catch (error) {
      console.error('API Error - createUser:', error);
      throw error;
    }
  },

  /**
   * Memperbarui data pengguna
   * @param {number|string} id - ID pengguna
   * @param {Object} userData - Data pengguna yang diperbarui
   * @returns {Promise} - Promise yang menghasilkan data pengguna yang diperbarui
   */
  updateCustomer: async (id, userData) => {
    try {
      const response = await api.put(`/customer/${id}`, userData);
      return response.data.data;
    } catch (error) {
      console.error(`API Error - updateUser ${id}:`, error);
      throw error;
    }
  },

  /**
   * Menghapus pengguna
   * @param {number|string} id - ID pengguna
   * @returns {Promise} - Promise yang menghasilkan respons penghapusan
   */
  deleteCustomer: async (id) => {
    try {
      const response = await api.delete(`/customer/${id}`);
      return response.data;
    } catch (error) {
      console.error(`API Error - deleteUser ${id}:`, error);
      throw error;
    }
  },

  /**
   * Customer Location
   */
  getCustomerLocation: async () => {
    try {
      const response = await api.get(`/${apiVersion}/customer-location`);
      return response.data;
    } catch (error) {
      console.error('API Error - getCustomerLocation:', error);
      throw error;
    }
  },
}