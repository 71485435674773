import api from './index';

const apiVersion = 'v2.0';

export const dashboardAPI = {
  /**
   * Mendapatkan daftar pengguna dengan paginasi dan pencarian
   * @param {number} page - Halaman saat ini
   * @param {number} perPage - Jumlah item per halaman
   * @param {string} search - Kata kunci pencarian
   * @returns {Promise} - Promise yang menghasilkan data dan metadata pengguna
   */
  getCounterDaily: async () => {
    try {
      const response = await api.get(`/${apiVersion}/dashboard/counter`);
      return response.data;
    } catch (error) {
      console.error('API Error - getUsers:', error);
      throw error;
    }
  },

  getChartDaily: async () => {
    try {
      const response = await api.get(`/${apiVersion}/dashboard/chart-daily`);
      return response.data;
    } catch (error) {
      console.error('API Error - getUsers:', error);
      throw error;
    }
  },
};