import React, { useState, useEffect } from 'react';
import { 
  Table, Button, Input, Space, Popconfirm, message, Card, 
  Typography, Row, Col, Modal, Form, Spin 
} from 'antd';
import { 
  PlusOutlined, SearchOutlined, EditOutlined, 
  DeleteOutlined, LockOutlined, ExclamationCircleOutlined 
} from '@ant-design/icons';

import { IconEye } from '@tabler/icons-react';

import { useNavigate } from 'react-router-dom';
import { customerAPI } from '../../api/customerAPI';

const { Title } = Typography;
const { confirm } = Modal;

const CustomerList = () => {
  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0
  });
  
  const [photoModalVisible, setPhotoModalVisible] = useState(false);
  const [selectedPhoto, setSelectedPhoto] = useState(null);
  
  const navigate = useNavigate();

  // Fungsi untuk memuat data user
  const fetchUsers = async (page = 1, pageSize = 10, search = '') => {
    try {
      setLoading(true);
      const response = await customerAPI.getCustomers(page, pageSize, search);
      
      setUsers(response.data);
      setPagination({
        current: page,
        pageSize: pageSize,
        total: response.meta.total
      });
    } catch (error) {
      message.error('Gagal memuat data pengguna');
      console.error('Error fetching users:', error);
    } finally {
      setLoading(false);
    }
  };

  // Load data saat komponen dimuat
  useEffect(() => {
    fetchUsers(pagination.current, pagination.pageSize, searchText);
  }, []);

  // Handle perubahan pagination dan filter
  const handleTableChange = (pagination) => {
    fetchUsers(pagination.current, pagination.pageSize, searchText);
  };

  // Handle search
  const handleSearch = () => {
    fetchUsers(1, pagination.pageSize, searchText);
  };

  // Fungsi untuk menghapus user
  const handleDelete = (userId) => {
    confirm({
      title: 'Apakah Anda yakin ingin menghapus pengguna ini?',
      icon: <ExclamationCircleOutlined />,
      content: 'Tindakan ini tidak dapat dibatalkan.',
      okText: 'Ya',
      okType: 'danger',
      cancelText: 'Tidak',
      onOk: async () => {
        try {
          await customerAPI.deleteCustomer(userId);
          message.success('Pengguna berhasil dihapus');
          fetchUsers(pagination.current, pagination.pageSize, searchText);
        } catch (error) {
          message.error('Gagal menghapus pengguna');
          console.error('Error deleting user:', error);
        }
      }
    });
  };

  // Fungsi untuk membuka modal foto
  const showPhotoModal = (photoUrl) => {
    setSelectedPhoto(photoUrl);
    setPhotoModalVisible(true);
  };

  // Konfigurasi kolom tabel
  const columns = [
    {
      title: 'Cover',
      dataIndex: 'cover',
      key: 'cover',
      render: (cover) => (
        <button
          onClick={() => showPhotoModal(cover)}
          style={{
            border: 'none',
            background: 'none',
            padding: 0,
            cursor: 'pointer'
          }}
          aria-label="View Customer Photo"
        >
          <img
            src={cover}
            alt="Customer"
            style={{ width: 50, height: 50 }}
          />
        </button>
      )
    },
    {
      title: 'Nama',
      dataIndex: 'name',
      key: 'name',
      render: (name, data) => {
        
        return (
            <span>
            <span style={{fontWeight: 'bold' }}>
              {name.toUpperCase()}
            </span>
            <br/>
            {data?.address}</span>
        );
      },
    },
    {
      title: 'Status',
      dataIndex: 'active',
      key: 'active',
      filters: [
        { text: 'Aktif', value: true },
        { text: 'Tidak Aktif', value: false }
      ],
      render: (active) => (
        <span style={{ 
          color: active ? 'green' : 'red',
          fontWeight: 'bold'
        }}>
          {active ? 'Aktif' : 'Tidak Aktif'}
        </span>
      )
    },
    {
      title: 'Last Visit',
      dataIndex: 'last_visit_at',
      key: 'last_visit_at',
      align: 'center',
      render: (last_visit_at, data) => (
        <span>
          <span style={{fontWeight: 'bold' }}>{data.last_visit_by}</span>
          <br/>
          {last_visit_at} 
        </span>
      )
    },
    {
      title: 'Action',
      key: 'action',
      align: 'center',
      width: 200,
      render: (_, record) => (
        <Space size="small">
          <Button 
            type="default" 
            icon={<IconEye />} 
            onClick={() => navigate(`/customers/view/${record.id}`)}
          >
            View
          </Button>
          <Button 
            type="primary" 
            icon={<EditOutlined />} 
            onClick={() => navigate(`/customers/edit/${record.id}`)}
          >
            Edit
          </Button>
          
          <Popconfirm
            title="Yakin hapus pengguna ini?"
            onConfirm={() => handleDelete(record.id)}
            okText="Ya"
            cancelText="Tidak"
          >
            <Button 
              danger 
              icon={<DeleteOutlined />}
            >
              Hapus
            </Button>
          </Popconfirm>
        </Space>
      )
    }
  ];

  return (
    <div>
      <Card>
        <Row justify="space-between" align="middle" style={{ marginBottom: 16 }}>
          <Col>
            <Title level={2}>Customer Management</Title>
          </Col>
          <Col>
            <Button
              type="primary"
              icon={<PlusOutlined />}
              onClick={() => navigate('/customers/create')}
            >
              Create New
            </Button>
          </Col>
        </Row>

        <Row style={{ marginBottom: 16 }}>
          <Col span={8}>
            <Input
              placeholder="Search..."
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
              onPressEnter={handleSearch}
              suffix={<SearchOutlined onClick={handleSearch} style={{ cursor: 'pointer' }} />}
            />
          </Col>
        </Row>

        <Table
          className="table-striped-rows"
          columns={columns}
          dataSource={users}
          rowKey="id"
          pagination={pagination}
          loading={loading}
          onChange={handleTableChange}
        />
      </Card>

      {/* Modal Foto */}
      <Modal
        title="Customer Photo"
        open={photoModalVisible}
        onCancel={() => setPhotoModalVisible(false)}
        footer={null}
      >
        <img src={selectedPhoto} alt="Customer" style={{ width: '100%' }} />
      </Modal>
    </div>
  );
};

export default CustomerList;