import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Card, Typography, Button, Spin, message, Tabs, Descriptions, Table, Row, Col } from 'antd';
import { ArrowLeftOutlined, DownloadOutlined} from '@ant-design/icons';
import { salesVisitAPI } from '../../api/salesVisitAPI';

const { Title } = Typography;
const { TabPane } = Tabs;

const SalesVisitDetail = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [salesVisit, setSalesVisit] = useState(null);
  const [exporting, setExporting] = useState(false);

  useEffect(() => {
    const fetchVisit = async () => {
      try {
        const response = await salesVisitAPI.getVisit(id);
        setSalesVisit(response);
      } catch (error) {
        message.error('Gagal memuat data pelanggan');
        console.error('Error fetching customer:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchVisit();
  }, [id]);

  const handleExport = async () => {
    try {
      setExporting(true); // Set loading state for the button
      const response = await salesVisitAPI.exportPDF(id); // Call the export endpoint

      const link = document.createElement('a');
      link.href = response;
      link.download = `sales-visit-${id}.pdf`; // Set the file name
      link.click();

      message.success('Export successful! Your file is being downloaded.');

      // If the response contains a file URL, trigger the download
      // if (response.file_url) {
      //   window.open(response.file_url, '_blank');
      // }
    } catch (error) {
      message.error('Failed to export data');
      console.error('Error exporting data:', error);
    } finally {
      setExporting(false); // Reset loading state
    }
  };

  if (loading) {
    return <Spin size="large" />;
  }


  
  return (
    <div>
      
      <Button
        type="link"
        icon={<ArrowLeftOutlined />}
        onClick={() => navigate('/sales-visit')}
      >
        Back to Sales Visit List
      </Button>
      <Row gutter={16}>
          <Col span={20}>
            Visit Details
          </Col>
          <Col span={4} style={{ textAlign: 'right' }}>
              
              
        </Col>
      </Row>
      
      <Row gutter={16}>
        
        <Col span={14}>
        <Card>
          <Row gutter={16}>
            <Col span={3}>
          <Button
                type="primary"
                icon={<DownloadOutlined />}
                loading={exporting} // Show loading spinner while exporting
                onClick={handleExport}
                block
              >
                
            </Button>
            </Col>
            </Row>
          <Row gutter={16}>
            <Col span={24}>
                <h3 style={{backgroundColor: '#1677ff', padding:'10px', borderRadius:'8px', color:'#FFF', fontWeight:600}}>Visit</h3>
                <Descriptions bordered column={1} size='small'>
                  <Descriptions.Item label="Sales">{salesVisit.created_by}</Descriptions.Item>
                  <Descriptions.Item label="Visit Date">{salesVisit.created_at}</Descriptions.Item>
                  <Descriptions.Item label="Distance" span={2}>{salesVisit.distance}</Descriptions.Item>
                  <Descriptions.Item label="Duration">{salesVisit.duration}</Descriptions.Item>
                </Descriptions>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={24}>
              <h3 style={{backgroundColor: '#1677ff', padding:'10px', borderRadius:'8px', color:'#FFF', fontWeight:600}}>Report</h3>
                <Descriptions bordered column={1} size='small'>
                  
                  {(salesVisit.notes) ? (
                    <React.Fragment key={salesVisit?.notes?.id}>
                      
                      <Descriptions.Item label="Price">{salesVisit?.notes?.price}</Descriptions.Item>
                      <Descriptions.Item label="Program">{salesVisit?.notes?.program}</Descriptions.Item>
                      <Descriptions.Item label="Needed" span={2}>{salesVisit?.notes?.needed}</Descriptions.Item>
                      <Descriptions.Item label="Remarks">{salesVisit?.notes?.remarks}</Descriptions.Item>
                    </React.Fragment>
                  ) : null}
                  
                </Descriptions>
            </Col>
          </Row>
        
          <Row gutter={16}>
            <Col span={24}>
            <h3 style={{backgroundColor: '#1677ff', padding:'10px', borderRadius:'8px', color:'#FFF', fontWeight:600}}>Order</h3>
                <Descriptions bordered column={1} size='small'>
                  
                  {salesVisit.order.map((order, index) => (
                    <React.Fragment key={index}>
                      
                      <Descriptions.Item label="Order Info"><span style={{ whiteSpace: 'pre-wrap' }}>{order.order_info}</span></Descriptions.Item>
                      <Descriptions.Item label="Payment">{order.payment_method}</Descriptions.Item>
                      <Descriptions.Item label="Status" span={2}>{order.status}</Descriptions.Item>
                    </React.Fragment>
                  ))}
                  
                </Descriptions>
            </Col>
          </Row>
          </Card>
        </Col>

        <Col span={10}>
          <Row gutter={16}>
            <Col span={24}>
              <Card title="Card title" variant="borderless" style={{ width: '100%' }}>
                <Descriptions bordered column={1} size='small'>
                  <Descriptions.Item label="Name">{salesVisit.customer.name.toUpperCase()}</Descriptions.Item>
                  <Descriptions.Item label="Address">{salesVisit.customer.address}</Descriptions.Item>
                  <Descriptions.Item label="Phone">{salesVisit.customer.phone}</Descriptions.Item>
                  <Descriptions.Item label="PIC" span={1}>{salesVisit.customer.pic}</Descriptions.Item>
                  <Descriptions.Item label="Last Visit">{salesVisit.last_visit_at}</Descriptions.Item>
                </Descriptions>
                </Card>
            </Col>
          </Row>
        </Col>
      </Row>
      </div>
  );
};

export default SalesVisitDetail;