import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Card, Typography, Button, Spin, message, Tabs, Descriptions, Table, Row, Col, Tag,
  Input, DatePicker } from 'antd';
 
import { ArrowLeftOutlined, SearchOutlined } from '@ant-design/icons';
import { salesAPI } from '../../api/salesAPI';
import { salesVisitAPI } from '../../api/salesVisitAPI';
import GoogleMapComponent from '../../components/GoogleMapComponent';
import dayjs from 'dayjs';
import moment from 'moment';
const { Title } = Typography;
const { TabPane } = Tabs;
const { RangePicker } = DatePicker;

const OrderDetail = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [order, setOrder] = useState(null);
  const [visits, setVisits] = useState([]);
  const [salesId, setSalesId] = useState(null);
const [dateRange, setDateRange] = useState([dayjs(moment().subtract(7, 'days')), dayjs(moment())]);
  const [searchText, setSearchText] = useState('');
  const [pagination, setPagination] = useState({
      current: 1,
      pageSize: 20,
      total: 0
  });
  const [markers, setMarkers] = useState([]);
  const [exporting, setExporting] = useState(false);

  const fetchVisitBySales = async (page = 1, pageSize = 20, search = '', dateRange = []) => {
    try {
      const params = {
        page,
        pageSize,
        search,
        startDate: dateRange[0] ? dateRange[0].format('YYYY-MM-DD') : undefined,
        endDate: dateRange[1] ? dateRange[1].format('YYYY-MM-DD') : undefined,
      };
  
      const response = await salesVisitAPI.getVisitBySales(salesId, params);
      setVisits(response.data);
      
      const markers = response.data.map((customer) => ({
        lat: parseFloat(customer?.geolocation ? customer.geolocation.split(',')[0].trim() : null),
        lng: parseFloat(customer?.geolocation ? customer.geolocation.split(',')[1].trim() : null),
        title: customer.customer_name,
        address: customer.customer_address,
        image: customer.image ? 'https://cdn.rms.web.id/' + customer.image : ''
      }));

      setMarkers(markers);

      setPagination({
        current: page,
        pageSize: pageSize,
        total: response.meta.total,
      });
    } catch (error) {
      message.error('Gagal memuat data riwayat kunjungan');
      console.error('Error fetching visit history:', error);
    }
  };

  useEffect(() => {
    const fetchDetail = async () => {
      try {
        const response = await salesAPI.getSalesById(id);

        setSalesId(response.username);
        setOrder(response);
      } catch (error) {
        message.error('Gagal memuat data pelanggan');
        console.error('Error fetching customer:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchDetail();
  }, [id])

  useEffect(() => {
    if (salesId) {
      fetchVisitBySales(1, pagination.pageSize, searchText, dateRange);
    }
  }, [salesId]);

  // Handle search
  const handleSearch = () => {
    fetchVisitBySales(1, pagination.pageSize, searchText, dateRange);
  };

  const handleTableChange = (pagination) => {
    fetchVisitBySales(1, pagination.pageSize, searchText, dateRange);
  };

  const handleDateRangeChange = (dates) => {
    setDateRange(dates);
    fetchVisitBySales(1, pagination.pageSize, searchText, dateRange);
  };

  if (loading) {
    return <Spin size="large" />;
  }

  if (!order) {
    return <div>Order not found</div>;
  }


  const columns = [
    {
      title: 'Customer',
      dataIndex: 'id',
      key: 'id',
      render: (value, row) => {
        return (
          <span>
          <span style={{ cursor: 'pointer', fontWeight:'bold' }}>
            {row.customer_name}
          </span>
          <br/>
          {row.customer_address}
          </span>
        )
      },
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (value, row) => {
        return (
          <span>
          <Tag color={row.status == '1' ? 'green' : 'warning'}>
            {row.status == '1' ? 'DONE' : 'ON PROGRESS'}
          </Tag>
          </span>
        )
      },
    },
    {
      title: 'Duration',
      dataIndex: 'duration',
      key: 'duration',
      align:'center',
      render: (value, row) => {
        return (
          <span style={{ cursor: 'pointer', fontWeight:'bold' }}>
            {row.duration} Min
          </span>
        )
      },
    },
    {
      title: 'Visit Date',
      dataIndex: 'created_at',
      key: 'created_at',
      align:'center',
      render: (value, row) => {
        return (
          <span>
            {value}
          </span>
        )
      },
    },
    
  ];

  const mapCenter = {
    lat: -6.200000,
    lng: 106.816666,
  };

  const handleExportPDF = async () => {
      try {
        setExporting(true); // Set loading state for the button
        const params = {
          startDate: dateRange[0] ? dateRange[0].format('YYYY-MM-DD') : undefined,
          endDate: dateRange[1] ? dateRange[1].format('YYYY-MM-DD') : undefined,
          username: salesId
        };
        const response = await salesVisitAPI.exportMultiVisitPDF({...params}); // Call the export endpoint
  
        message.success('Request export, the proccess will.');
  
      } catch (error) {
        message.error('Failed to export data');
        console.error('Error exporting data:', error);
      } finally {
        setExporting(false); // Reset loading state
      }
    };

  return (
    <Card>
      <Button
        type="link"
        icon={<ArrowLeftOutlined />}
        onClick={() => navigate('/sales-team')}
      >
        Back to Customer List
      </Button>
      <Title level={2}>Sales Activity</Title>
      <Row gutter={16}>
        <Col span={16}>
        <Row style={{ marginBottom: 16 }} gutter={16}>
          <Col span={8}>
            <Input
              placeholder="Cari pengguna..."
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
              onPressEnter={handleSearch}
              suffix={<SearchOutlined onClick={handleSearch} style={{ cursor: 'pointer' }} />}
            />
          </Col>
          <Col span={8}>
            <RangePicker
              value={dateRange}
              onChange={handleDateRangeChange}
              format="DD/MM/YYYY"
            />
          </Col>
          <Col span={4}>
            <Button
              type="primary"
              onClick={handleExportPDF}
            >
              Export As PDF
            </Button>
          </Col>
        </Row>
        <Table
          className="table-striped-rows"
                columns={columns}
                dataSource={visits}
                rowKey="id"
                pagination={pagination}
                loading={loading}
                onChange={handleTableChange}
              />
        </Col>
        <Col span={8}>
          <Descriptions title="Sales Detail" size='small' bordered column={1}>
            <Descriptions.Item label="Sales Name">{order.name}</Descriptions.Item>
            <Descriptions.Item label="Phone">{order.phone}</Descriptions.Item>
            
          </Descriptions>

          <div style={{ marginTop: 16 }}>
            <GoogleMapComponent markers={markers} center={mapCenter} zoom={8} />
          </div>
        </Col>
      </Row>
      
    </Card>
  );
};

export default OrderDetail;