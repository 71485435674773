import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Card, Typography, Button, Spin, message, Tabs, Descriptions, Table, Row, Col, Tag } from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { customerAPI } from '../../api/customerAPI';
import { salesVisitAPI } from '../../api/salesVisitAPI';
import { orderAPI } from '../../api/orderAPI';

const { Title } = Typography;
const { TabPane } = Tabs;

const CustomerDetail = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [customer, setCustomer] = useState(null);
  const [salesVisits, setSalesVisits] = useState([]);
  const [orderHistory, setOrderHistory] = useState([]);

  useEffect(() => {
    const fetchCustomer = async () => {
      try {
        const response = await customerAPI.getCustomer(id);
        setCustomer(response);
      } catch (error) {
        message.error('Gagal memuat data pelanggan');
        console.error('Error fetching customer:', error);
      } finally {
        setLoading(false);
      }
    };

    const fetchSalesVisits = async () => {
      try {
        const response = await salesVisitAPI.getVisitByCustomer(id);
        setSalesVisits(response.data);
      } catch (error) {
        message.error('Gagal memuat data kunjungan sales');
        console.error('Error fetching sales visits:', error);
      }
    };

    const fetchOrderHistory = async () => {
      try {
        const response = await orderAPI.getOrderByCustomer(id);
        console.log(response);
        setOrderHistory(response.data);
      } catch (error) {
        message.error('Gagal memuat data riwayat pesanan');
        console.error('Error fetching order history:', error);
      }
    };

    fetchCustomer();

    fetchSalesVisits();
    
    fetchOrderHistory();
  }, [id]);

  if (loading) {
    return <Spin size="large" />;
  }

  if (!customer) {
    return <div>Customer not found</div>;
  }

  const salesVisitColumns = [
    {
      title: 'Date',
      dataIndex: 'created_at',
      key: 'created_at',
    },
    {
      title: 'Sales',
      dataIndex: 'created_by',
      key: 'created_by',
    },
    {
      title: 'Report',
      dataIndex: 'report',
      key: 'report',
      render: (value, data) => {
        return (
          <span>
            <span style={{ fontWeight: 'bold' }}>Price : </span>
            <span>{value?.price}</span>
            <br />
            <span style={{ fontWeight: 'bold' }}>Program : </span>
            <span>{value?.program}</span>
            <br />
            <span style={{ fontWeight: 'bold' }}>Needed : </span>
            <span>{value?.needed}</span>
            <br />
            <span style={{ fontWeight: 'bold' }}>Remarks : </span>
            <span>{value?.remarks}</span>
          </span>
        )
      }
    },
  ];

  const orderHistoryColumns = [
    {
      title: 'Date',
      dataIndex: 'created_at',
      key: 'created_at',
    },
    {
      title: 'Sales',
      dataIndex: 'created_by',
      key: 'created_by',
    },
    {
      title: 'Order Info',
      dataIndex: 'order_info',
      key: 'order_info',
    },
    {
      title: "Status",
      dataIndex: 'status',
      key: 'status',
      align:'center',
      width: 100,
      render: (value, data) => {
        return (
          <Tag color={value === 'completed' ? 'green' : 'blue'}>
            {value}
            </Tag>
        );
      }
    },
    {
        title: "Payment",
        dataIndex: 'payment',
        key: 'payment',
        align:'center',
        width: 100,
        render: (value, data) => {
            return (
              <Tag color={value === 'TUNAI' ? 'green' : 'volcano'}>
                {value}
                </Tag>
            );
        }
    },
  ];

  return (
    <Card>
      <Button
        type="link"
        icon={<ArrowLeftOutlined />}
        onClick={() => navigate('/customers')}
      >
        Back to Customer List
      </Button>
      <Title level={2}>Customer Details</Title>
      <Row gutter={16}>
        <Col span={4}>
          <img src={customer.cover} alt="Customer" style={{ width: '100%' }} />
        </Col>
        <Col span={20}>
            <Descriptions bordered column={1} size='small'>
            <Descriptions.Item label="Name">{customer.name}</Descriptions.Item>
            <Descriptions.Item label="Address">{customer.address}</Descriptions.Item>
            <Descriptions.Item label="Phone" span={2}>{customer.phone}</Descriptions.Item>
            <Descriptions.Item label="PIC">{customer.pic_name}</Descriptions.Item>
          </Descriptions>
        </Col>
      </Row>
      <Tabs defaultActiveKey="1" style={{ marginTop: 20 }} badge={10}>
        <TabPane tab="Sales Visit History" key="1" badge={10}>
          <Table
            className="table-striped-rows"
            columns={salesVisitColumns}
            dataSource={salesVisits}
            rowKey="id"
            pagination={false}
          />
        </TabPane>
        <TabPane tab="Order History" key="2">
          <Table
            className="table-striped-rows"
            columns={orderHistoryColumns}
            dataSource={orderHistory}
            rowKey="order_id"
            pagination={false}
          />
        </TabPane>
      </Tabs>
    </Card>
  );
};

export default CustomerDetail;