import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import OrderList from './OrderList';
import OrderDetail from './OrderDetail';

const SalesVisit = () => {
  return (
    <Routes>
      <Route path="/" element={<OrderList />} />
      <Route path="/view/:id" element={<OrderDetail />} />
      <Route path="*" element={<Navigate to="/work-order" />} />
    </Routes>
  );
};

export default SalesVisit;