import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import SalesVisitList from './SalesVisitList';
import SalesVisitDetail from './SalesVisitDetail';

const SalesVisit = () => {
  return (
    <Routes>
      <Route path="/" element={<SalesVisitList />} />
      <Route path="/view/:id" element={<SalesVisitDetail />} />
      <Route path="*" element={<Navigate to="/sales-visits" />} />
    </Routes>
  );
};

export default SalesVisit;